<template>
  <statistics-category :name="name" :category="category"></statistics-category>
</template>
<script>
import StatisticsCategory from "../statisticspercateogry";
export default {
    name: 'statisticsCategoryHotelScore',
    props: {
      category: {
        type: String,
      },
      name: {
        type: String,
      }
    },
  components: {StatisticsCategory}
}
</script>